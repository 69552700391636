@font-face {
  font-family: 'MyFont';
  src: local('MyFont'), url(./fonts/raj-2.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  font-family: MyFont;
  font-size: 25pt;
  letter-spacing: -1px;
  font-weight:700;
  line-height: 1;
  /*width: 100%;*/
  /*height: 100%;*/
  /*background-image: url("../public/img/paper.png");*/
}

a {
  text-decoration: none;
  color: black;
}

h3, h2, h1{
  font-weight: bolder;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#menu-bar {
  list-style-type: none;
  margin-top: 3%;
  padding: 0;
  overflow: hidden;
  /*background-color: #333;*/
}

#menu-item {
  float: right;
}

#menu-item-link {
  display: block;
  /*padding: 8px;*/
  color: black;
  text-decoration: none;
  width: 120px;
}

.active {
  /*padding-bottom: 20px;*/
  border-bottom: 2px solid gray;
  /*margin-top: -10px;*/
  color: black!important;
  /*border-radius: 10px;*/
}

#menu-item-link:hover{
  /*filter: blur(0.8px);*/
  color: gray;
  /*background-color: black;*/
}

.inlineLi {
  display: inline;
  border-radius: 15px;
  border: 1px solid black;
  background:  black;
  box-shadow: 0 0 20px rgba(0,0,0,.12);
  color: white;
  margin-right: 5px;
  padding: 3px 5px 3px 5px;
}

.cvHeader {
  margin: 0 0 0 10px ;
  text-align: left;
}

.cvSection {

}